import React, { useState, useEffect } from "react";
import logo from "../Resources/logo.png";
import {
  Button,
  Card,
  Form,
  Grid,
  Image,
  Input,
  Message,
  Icon,
  ButtonGroup,
  Label,
} from "semantic-ui-react";
import { useAuth } from "../Context/AuthContext";
import {
  person2Fellow,
  loginSendOTP,
  loginVerifyOTP,
  person2Admin,
  fetchAuthFacAndAdhoc,
  checkHostelUserRole,
  checkEmployeeRoleForComplaint,
} from "./api";
import { checkPsyCounsellorRole } from "./Psy/api";
import { Link } from "react-router-dom";
import { useTitle } from "../Hooks/useTitle";

function Login() {
  useTitle("Login")
  const { login } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [msg, setMsg] = useState({
    visible: false,
    color: "",
    msg: "",
    icon: "",
  });

  const [otp, setOtp] = useState("");
  const [stage, setStage] = useState("phoneInput"); // 'phoneInput' or 'otpInput'
  const [loading, setLoading] = useState(false); // State to manage the loading spinner visibility
  const [timer, setTimer] = useState(120); // Timer for OTP resend
  const [showResend, setShowResend] = useState(false);

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  useEffect(() => {
    let interval;
    if (stage === "otpInput" && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, stage]);

  const resendOTP = (e) => {
    handlePhoneSubmit(e);
    setShowResend(false);
    setTimer(120);
    // Call your API to resend OTP here
    // console.log("Resending OTP to:", phoneNumber);
  };

  const handleNumericInput = (event, setter) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const handlePhoneSubmit = (e) => {
    setMsg({ visible: false });
    e.preventDefault();
    if (phoneNumber.length === 10) {
      setLoading(true); // Start loading
      loginSendOTP(phoneNumber)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            // console.log("error")
            // setErrorMsg(data.error);
            setMsg({
              visible: true,
              color: "red",
              msg: data.error,
              icon: "",
            });
            setLoading(false);
          } else {
            // console.log(data);
            setStage("otpInput");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("This is error: ", err);
        });
      localStorage.setItem("mob", phoneNumber);
      // console.log("API Call to send OTP to:", phoneNumber);
    } else {
      //   alert("Please enter a valid 10-digit phone number.");
      setLoading(false);
      setMsg({
        visible: true,
        color: "yellow",
        msg: "Please enter a valid 10-digit phone number.",
        icon: "warning",
      });
    }
  };

  async function checkSrk(personType, personId) {
    if (personType === "STU") {
      await person2Fellow(personId)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
          } else {
            localStorage.setItem("srk_fellow_id", data.fellow_id);
          }
        });
    } else {
      await person2Admin(personId)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
          } else {
            localStorage.setItem("srk_admin_id", data.admin_id);
          }
        });
    }
  }

  const handleOtpSubmit = async (e) => {
    setMsg({ visible: false });
    e.preventDefault();
    if (otp.length === 6) {
      setLoading(true); // Start loading
      loginVerifyOTP(phoneNumber, otp)
        .then((res) => res.json())
        .then(async (data) => {
          if (data.error) {
            // setErrorMsg(data.error);
            setMsg({
              visible: true,
              color: "red",
              msg: data.error,
              icon: "",
            });

            setLoading(false);
          } else {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("person_id", data.person_id);
            localStorage.setItem("person_t", data.person_t);
            localStorage.setItem("name", data.name);
            if (data.person_t !== "STU") {
              localStorage.setItem("authfac_id", data.authfac_id);
            }
            await checkSrk(data.person_t, data.person_id);
            await checkHostelUserRole();
            // await checkEmployeeRoleForComplaint();
            await checkPsyCounsellorRole();
            try {
              await fetchAuthFacAndAdhoc(data.person_id)
                .then((res) => res.json())
                .then((data) => {
                  if (data.error) {
                  } else {
                    localStorage.setItem("adhoc_group", data.adhoc_group);
                  }
                });
            } catch (error) {
              console.log("adhoc error: " + error);
            }
            login();
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(false);
      setMsg({
        visible: true,
        color: "yellow",
        msg: "Please enter a valid 6-digit OTP.",
        icon: "warning",
      });
    }
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Card centered>
          <div
            style={{
              position: "absolute",
              textAlign: "left",
              zIndex: 1000,
              marginTop: "1rem",
            }}
          >
            <Link to={"/"}>
              <Label ribbon color="violet">
                <Icon name="home" />
                Home
              </Label>
            </Link>
          </div>
          <Image
            src={logo}
            centered
            size="medium"
            style={{ userSelect: "none", padding: "10px" }}
            draggable="false"
          />
          <Card.Content>
            {stage === "phoneInput" ? (
              <Form onSubmit={handlePhoneSubmit}>
                <label>Mobile Number</label>
                <Form.Field>
                  {/* {phoneNumber.length === 10 ? } */}
                  <Input
                    type="tel"
                    placeholder="Enter mobile number"
                    label={{
                      basic: phoneNumber.length === 10 ? false : true,
                      content: phoneNumber.length + "/10",
                      color: phoneNumber.length === 10 ? "green" : "",
                    }}
                    labelPosition="right"
                    value={phoneNumber}
                    onChange={(e) => handleNumericInput(e, setPhoneNumber)}
                    maxLength="10"
                  />
                </Form.Field>
                <Button
                  inverted
                  fluid
                  type="submit"
                  size="small"
                  color="violet"
                  loading={loading}
                >
                  Get OTP
                </Button>
              </Form>
            ) : (
              <Form onSubmit={handleOtpSubmit}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>OTP (One Time Password)</label>
                  {showResend ? (
                    ""
                  ) : (
                    <span style={{ color: "gray", fontSize: "0.8rem" }}>
                      Resend OTP in {timer} seconds
                    </span>
                  )}
                </div>

                <Form.Field>
                  <Input
                    type="tel"
                    label={{
                      basic: otp.length === 6 ? false : true,
                      content: otp.length + "/6",
                      color: otp.length === 6 ? "green" : "",
                    }}
                    labelPosition="right"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => handleNumericInput(e, setOtp)}
                    maxLength="6"
                  />
                </Form.Field>
                <ButtonGroup fluid>
                  <Button
                    inverted
                    type="submit"
                    size="small"
                    color="green"
                    loading={loading}
                  >
                    Login
                  </Button>
                  {showResend ? (
                    <Button
                      inverted
                      size="small"
                      color="blue"
                      onClick={(e) => resendOTP(e)}
                      loading={loading}
                    >
                      Resend OTP
                    </Button>
                  ) : (
                    ""
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Card.Content>
          {msg.visible ? (
            <Message attached="bottom" color={msg.color}>
              {msg.icon ? <Icon name={msg.icon} /> : ""}
              {msg.msg}
            </Message>
          ) : (
            ""
          )}
        </Card>
      </Grid.Column>
    </Grid>
  );
}

export default Login;
