/**
 * @author @ans-sharma - ak.sharma@bhu.ac.in
 * @description Faculty Course Syllabus Management System
 * @date 2023-02-20
 *
 * This module manages the syllabi for faculty courses, allowing users to select programs and courses, view syllabi,
 * update syllabi, remove courses, and manage course selections.
 *
 * Main Functions:
 * - handleProgramSelection: Handles program selection changes
 * - handleCourseSelection: Handles course selection changes
 * - handleSyllabusUpdate: Updates the syllabus text
 * - handleRemoveCourses: Removes selected courses from the faculty courses list
 * - clearAllData: Clears all data related to the faculty courses and selections
 *
 * Variables:
 * - facultyCourses: List of faculty courses with their corresponding syllabi
 * - programOptions: List of available programs for selection
 * - selectedProgramId: Selected program ID
 * - searchQuery: Search query string
 * - filteredCourses: Filtered list of courses based on the search query
 * - selectedCourses: List of selected courses
 * - selectedCoursesForRemoval: List of selected courses to remove
 *
 * Function Arguments and Return Type:
 * - handleProgramSelection(value): Updates the selected program ID when a new value is selected (void)
 * - handleCourseSelection(courseId): Updates the list of selected courses when a new course ID is selected (void)
 * - handleSyllabusUpdate(): Updates the syllabus text in the syllabus modal (void)
 * - handleRemoveCourses(): Removes selected courses from the faculty courses list and updates the selections (void)
 * - clearAllData(): Clears all data related to the faculty courses and selections (void)
 *
 * Brief Summary:
 * This module provides a comprehensive system for managing faculty course syllabi, allowing users to select programs
 * and courses, view syllabi, update syllabi, remove courses, and manage course selections.
 */


import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Button,
  Container,
  Modal,
  Input,
  Checkbox,
  Header,
  Loader,
  Message,
  Card,
  TextArea,
  Form,
  Dimmer,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import {
  fetchFacultyCourses,
  updateFacultyCourses,
  fetchCourseDetails,
  updateCourseDetails,
  fetchProgramsList,
  fetchCoursesList,
  fetchProgramDetails,
  addFacultyCoursesToNewMapping,
  delFacultyCoursesToNewMapping,
} from "../api";
import LoaderComponent from "../LoaderComponent";
import "../../Css/SrkInbox.css"; // Import the CSS


export default function FacultyCourseMap({ person_id }) {
  const [facultyCourses, setFacultyCourses] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]); // State for programs
  const [selectedProgramId, setSelectedProgramId] = useState(null); // Selected program
  const [allCourses, setAllCourses] = useState([]); // State for courses of the selected program
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false); // Loader state for the modal
  const [isUpdatingCourses, setIsUpdatingCourses] = useState(false); // Loader for updating courses
  const [isSyllabusModalOpen, setIsSyllabusModalOpen] = useState(false); // State for syllabus modal
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCourseDetails, setSelectedCourseDetails] = useState(null); // State to hold course details
  const [isFetchingCourseDetails, setIsFetchingCourseDetails] = useState(false); // Loader for fetching course details
  const [syllabusText, setSyllabusText] = useState(""); // State for syllabus text area
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [selectedCoursesForRemoval, setSelectedCoursesForRemoval] = useState(
    []
  );

  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  useEffect(() => {
    if (person_id) {
      fetchFacultyCoursesData();
    }
  }, [person_id]);

  const clearAllData = () => {
    setSelectedProgramId(null);
    setSelectedCourses([]);
  };

  const fetchFacultyCoursesData = async () => {
    setLoading(true);
    try {
      const response = await fetchFacultyCourses(person_id);
      const result = await response.json();
      // console.log(result.courses);
      // console.log(result.new_courses);
      // console.log(
      //   result.new_courses.map((n_course) => {
      //     return n_course.course;
      //   })
      // );
      setFacultyCourses(
        result.new_courses.map((n_course) => {
          let course = n_course.course;
          course["new_id"] = n_course.id;
          return n_course.course;
        })
      );
      // setSelectedCourses(result.courses.map((course) => course.id)); // Pre-select courses
    } catch (error) {
      console.error("Error fetching faculty courses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProgramSelection = (programId) => {
    setSelectedProgramId(programId);
    fetchProgramDetails(programId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setAllCourses(data.courses);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Could not fetch program details: ", err);
        setLoading(false);
      });
  };

  const handleCourseSelection = (courseId) => {
    setSelectedCourses((prevSelected) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  const handleCourseRemovalSelection = (courseId) => {
    setSelectedCoursesForRemoval((prevSelected) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  const handleRemoveCourses = async () => {
    try {
      const payload = {
        person_id: person_id,
        course_ids: selectedCoursesForRemoval,
      };
      await delFacultyCoursesToNewMapping(payload);
      console.log(payload);
      setModalOpen(false);
      fetchFacultyCoursesData(); // Refresh the faculty courses list
    } catch (error) {
      console.error("Error deleting courses:", error);
    } finally {
      setIsRemoveModalOpen(false);
      setSelectedCoursesForRemoval([]);
    }
  };

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUpdateCourses = async () => {
    setIsUpdatingCourses(true); // Start loader while updating courses
    try {
      const payload = {
        person_id: person_id,
        program_id: selectedProgramId,
        courses_id: selectedCourses,
      };
      await addFacultyCoursesToNewMapping(payload);
      console.log(payload);
      clearAllData();
      setModalOpen(false);
      setSearchQuery(""); // Clear search input after update
      fetchFacultyCoursesData(); // Refresh the faculty courses list
    } catch (error) {
      console.error("Error updating courses:", error);
    } finally {
      setIsUpdatingCourses(false); // Stop loading after update
      if (selectedCourseDetails) {
        await handleCourseClick(selectedCourseDetails.id); // Refresh course details if one is selected
      }
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    fetchProgramsList()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setAllPrograms(data);
          // setFilteredPrograms(data);
        } else {
          console.error("Could not find programs data in the response");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Could not fetch programs list: ", err);
        setLoading(false);
      });
  };

  const handleCourseClick = async (courseId) => {
    setIsFetchingCourseDetails(true); // Start loader when fetching course details
    try {
      const response = await fetchCourseDetails(courseId);
      const result = await response.json();
      setSelectedCourseDetails(result); // Store course details
      setSyllabusText(result.syllabus || ""); // Set syllabus text area with existing syllabus or blank
    } catch (error) {
      console.error("Error fetching course details:", error);
    } finally {
      setIsFetchingCourseDetails(false); // Stop loader
    }
  };

  const handleSyllabusUpdate = async () => {
    setIsFetchingCourseDetails(true); // Start loader while updating syllabus
    try {
      const formData = new FormData();
      formData.append("syllabus", syllabusText);
      await updateCourseDetails(selectedCourseDetails.id, formData); // Sending course ID and form data to the API
      console.log("Syllabus updated successfully");
      setIsSyllabusModalOpen(false); // Close the modal after successful update
      await handleCourseClick(selectedCourseDetails.id); // Refresh course details after update
    } catch (error) {
      console.error("Error updating syllabus:", error);
    } finally {
      setIsFetchingCourseDetails(false); // Stop loader
    }
  };

  // Function to handle new lines in syllabus
  const formatSyllabus = (syllabus) => {
    return { __html: syllabus.replace(/\n/g, "<br/>") };
  };

  // Filter and sort courses so selected courses appear first and search by both course code and name
  const filteredCourses = allCourses
    .filter(
      (course) =>
        course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        course.code.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = selectedCourses.includes(a.id);
      const bSelected = selectedCourses.includes(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

  const programOptions = allPrograms.map((program) => ({
    key: program.id,
    text: program.name,
    value: program.id,
  }));

  return (
    <Card fluid style={{ minHeight: "70vh" }}>
      <Card.Content>
        <Grid className="srk-inbox-grid" stackable>
          {isLoading ? (
            <LoaderComponent loadingText={"Loading Courses..."} />
          ) : (
            <>
              <Grid.Column
                width={facultyCourses.length > 0 ? 9 : 16} // Adjust width based on course selection
                className="srk-inbox-column"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <Container textAlign="right" style={{ marginBottom: "10px" }}>
                  <Button color="blue" onClick={handleOpenModal}>
                    <Icon name="add" />
                    Add Courses
                  </Button>
                  {facultyCourses.length > 0 && (
                    <Button color="red" onClick={handleOpenRemoveModal}>
                      <Icon name="trash" />
                      Remove Courses
                    </Button>
                  )}
                </Container>
                {facultyCourses.length > 0 ? (
                  <Table
                    selectable
                    striped
                    color="blue"
                    unstackable
                    singleLine
                    fixed
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>
                          Course Code
                        </Table.HeaderCell>
                        <Table.HeaderCell width={5}>
                          Course Name
                        </Table.HeaderCell>
                        <Table.HeaderCell width={6}>Program</Table.HeaderCell>
                        <Table.HeaderCell width={2} textAlign="right">
                          Semester
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facultyCourses.map((course, index) => (
                        <Table.Row
                          key={course.id}
                          style={{
                            backgroundColor:
                              rowColors[index % rowColors.length],
                            cursor: "pointer",
                          }}
                          onClick={() => handleCourseClick(course.id)} // Handle click to fetch course details
                        >
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.code}</div>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.name}</div>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.program}</div>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">
                              {course.for_semester}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Message
                    info
                    content="No courses have been added yet. Click the button above to add or update courses."
                  />
                )}
              </Grid.Column>

              {isFetchingCourseDetails ? (
                <LoaderComponent loadingText={"Fetching Course Details..."} />
              ) : (
                <Grid.Column width={7}>
                  {facultyCourses.length > 0 && selectedCourseDetails ? (
                    <Card fluid>
                      <Card.Content>
                        <Header as="h4">{selectedCourseDetails.name}</Header>
                        <div
                          dangerouslySetInnerHTML={formatSyllabus(syllabusText)}
                        />
                      </Card.Content>
                      <Card.Content extra>
                        <Button
                          color="blue"
                          onClick={() => setIsSyllabusModalOpen(true)}
                        >
                          <Icon name="edit" />
                          Update Syllabus
                        </Button>
                      </Card.Content>
                    </Card>
                  ) : (
                    facultyCourses.length > 0  && <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                        zIndex: 1001,
                      }}
                    >
                      <Message info>
                        <Message.Header>
                          Select a course to view the syllabus
                        </Message.Header>
                      </Message>
                    </div>
                  )}
                </Grid.Column>
              )}
            </>
          )}
        </Grid>
      </Card.Content>

      {/* Modal for adding/updating courses */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          clearAllData();
          setModalOpen(false);
        }}
      >
        <Modal.Header>Select Program and Courses</Modal.Header>
        <Modal.Content>
          <Form style={{ height: "60vh" }}>
            {/* Program Selection */}
            <Form.Field>
              <label>Select Program</label>
              <Dropdown
                placeholder="Select a program"
                fluid
                search
                selection
                options={programOptions}
                onChange={(e, { value }) => handleProgramSelection(value)}
                value={selectedProgramId || ""}
              />
            </Form.Field>

            {/* Courses Checkbox */}
            {selectedProgramId && (
              <>
                <Input
                  fluid
                  icon="search"
                  placeholder="Search courses..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ marginBottom: "1rem" }}
                />
                {isModalLoading ? (
                  <Loader active inline="centered" />
                ) : (
                  <div style={{ maxHeight: "45vh", overflowY: "auto" }}>
                    {filteredCourses.map((course) => (
                      <Checkbox
                        key={course.id}
                        label={`${course.code} - ${course.name}`}
                        checked={selectedCourses.includes(course.id)}
                        onChange={() => handleCourseSelection(course.id)}
                        style={{ display: "block", marginBottom: "10px" }}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button
            color="green"
            onClick={handleUpdateCourses}
            loading={isUpdatingCourses}
          >
            Save Changes
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Modal for updating syllabus */}
      <Modal
        open={isSyllabusModalOpen}
        onClose={() => setIsSyllabusModalOpen(false)}
        size="large"
      >
        <Modal.Header>Update Syllabus</Modal.Header>
        <Modal.Content>
          <Form>
            <TextArea
              value={syllabusText}
              onChange={(e) => setSyllabusText(e.target.value)}
              rows={25}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsSyllabusModalOpen(false)}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button color="blue" onClick={handleSyllabusUpdate}>
            <Icon name="save" />
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      >
        <Modal.Header>Select Courses to Remove</Modal.Header>
        <Modal.Content>
          <Form>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {facultyCourses.map((course) => (
                <Checkbox
                  key={course.id}
                  label={`${course.code} - ${course.name}`}
                  checked={selectedCoursesForRemoval.includes(course.new_id)}
                  onChange={() => handleCourseRemovalSelection(course.new_id)}
                  style={{ display: "block", marginBottom: "10px" }}
                />
              ))}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsRemoveModalOpen(false)}>Cancel</Button>
          <Button color="red" onClick={handleRemoveCourses}>
            Remove
          </Button>
        </Modal.Actions>
      </Modal>
    </Card>
  );
}