import React, { useState, useEffect, memo, useCallback } from "react";
import logo from "../Resources/logo.png";
import {
  Menu,
  Button,
  Image,
  Modal,
  ModalHeader,
  ModalActions,
  ModalContent,
  Sidebar,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Css/Navbar.css";
import "../Css/Print.css";
import NavbarSearchComponent from "./NavbarSearchComponent";
import { fetchUserData, isUserAdmin, isUserCSEOPAdmin } from "./api";
import ProfileCard from "./Cards/ProfileCard";

const Navbar = ({ onLogout, setModalOpen2 }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [profileSidebarVisible, setProfileSidebarVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [placeholder, setPlaceholder] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [userData, setUserData] = useState(null);
  const defaultImage = require("../Resources/person.png");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const returnBackButton = useCallback(() => {
    const goBack = () => {
      navigate(-1); // This will navigate back to the previous page using React Router
    };

    if (currentPath === "/") return null; // Do not show the back button on the home page

    const left = isMobile ? "10px" : "20px";
    return (
      <div
        style={{ position: "fixed", left: left, top: "85px", zIndex: "1000" }}
      >
        <Button icon circular color="blue" onClick={goBack}>
          <Icon name="arrow left" />
        </Button>
      </div>
    );
  }, [currentPath, isMobile, navigate]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const placeholderTexts = [
      "search about your department...",
      "find information about holidays...",
      "look up contact details...",
      "explore the telephone directory...",
      "check notifications and inbox...",
    ];
    if (typingIndex < placeholderTexts[textIndex].length) {
      const typingTimeout = setTimeout(() => {
        setPlaceholder(
          (prev) => prev + placeholderTexts[textIndex][typingIndex]
        );
        setTypingIndex((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(typingTimeout);
    } else {
      const nextTextTimeout = setTimeout(() => {
        setTypingIndex(0);
        setPlaceholder("");
        setTextIndex((prev) => (prev + 1) % placeholderTexts.length);
      }, 1500);
      return () => clearTimeout(nextTextTimeout);
    }
  }, [typingIndex, textIndex]);

  useEffect(() => {
    const person_id = localStorage.getItem("person_id");
    if (person_id) {
      fetchUserData(person_id)
        .then((data) => {
          setUserData(data.details.person);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 1200);
  }, []);

  const srkMember =
    localStorage.getItem("srk_admin_id") ||
    localStorage.getItem("srk_fellow_id")
      ? true
      : false;
  const username = localStorage.getItem("name");
  const authfac_id = localStorage.getItem("authfac_id");
  const faculty = localStorage.getItem("person_t") === "FAC" ? true : false;
  const teachOrStaff =
    localStorage.getItem("person_t") !== "STU" ? true : false;
  const hostelAdminMember =
    localStorage.getItem("hostel_admin_id") ||
    localStorage.getItem("hostel_warden_id")
      ? true
      : false;
  const complaintSalaryMember =
    localStorage.getItem("complaint_salary_employee_id") ||
    localStorage.getItem("complaint_salary_admin_id")
      ? true
      : false;
  const psyCounselingMember =
    localStorage.getItem("psy_counsellor_id") ||
    localStorage.getItem("psy_admin_id")
      ? true
      : false;
  const personType = localStorage.getItem("person_t");
  const uwdUrl = process.env.REACT_APP_UWD_URL;

  return (
    <>
      {isMobile ? (
        <>
          <Menu borderless fixed="top" fluid>
            <Menu.Item as={Link} to="/" link>
              <Image src={logo} width="40" height="50" draggable="false" />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Icon
                  name="search"
                  onClick={() => setModalOpen2(true)}
                  style={{ cursor: "pointer" }}
                />
              </Menu.Item>
              <Menu.Item onClick={() => setSidebarVisible(true)}>
                <Icon name="sidebar" size="large" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Sidebar
            as={Menu}
            direction="right"
            animation="overlay"
            onHide={() => setSidebarVisible(false)}
            vertical
            visible={sidebarVisible}
            width="thin"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Menu.Item
              className="centered-content"
              style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
            >
              <Image
                src={logo}
                width="80"
                height="150"
                draggable="false"
                centered
                style={{ paddingBottom: "2rem" }}
              />
              <span style={{ fontSize: "1.2rem", marginBottom: "5rem" }}>
                Namaste BHU
              </span>
              <div>
                <br />
                Welcome 🙏
                <br />
                {username}
              </div>
            </Menu.Item>
            <div style={{ flex: 1 }}>
              <Menu.Item
                as={Link}
                to="/notifications"
                active={currentPath === "/notifications"}
              >
                Notifications
              </Menu.Item>
              {teachOrStaff && (
                <Menu.Item
                  as={Link}
                  to="/directory"
                  active={currentPath === "/directory"}
                >
                  Telephone Directory
                </Menu.Item>
              )}
              <Menu.Item
                as={Link}
                to="/faculty/search"
                active={currentPath === "/faculty/search"}
              >
                Faculty Search
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/holidays"
                active={currentPath === "/holidays"}
              >
                Holiday Calendar
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/central-library"
                active={currentPath === "/central-library"}
              >
                Central Library
              </Menu.Item>
              {srkMember && (
                <Menu.Item as={Link} to="/srk" active={currentPath === "/srk"}>
                  SRK
                </Menu.Item>
              )}
              {hostelAdminMember && (
                <Menu.Item
                  as={Link}
                  to="/wardens"
                  active={currentPath === "/wardens"}
                >
                  Wardens
                </Menu.Item>
              )}
              {complaintSalaryMember && (
                <Menu.Item
                  as={Link}
                  to="/issues/salary"
                  active={currentPath === "/issues/salary"}
                >
                  Salary Issues
                </Menu.Item>
              )}
              {psyCounselingMember && (
                <Menu.Item
                  as={Link}
                  to="/psy/counseling"
                  active={currentPath === "/psy/counseling"}
                >
                  Psy. Counseling
                </Menu.Item>
              )}
              {personType !== "STU" && (
                <Menu.Item as={Link} to={uwdUrl}>
                  UWD
                </Menu.Item>
              )}
              {faculty && (
                <Menu.Item as={Link} to="/mycourses">
                  My Courses
                </Menu.Item>
              )}
              {isUserAdmin() && (
                <Menu.Item as={Link} to="/namaste-admin">
                  Admin
                </Menu.Item>
              )}
              {isUserCSEOPAdmin() && (
                <Menu.Item as={Link} to="/cseop/attendance">
                  CSEOP Attendance
                </Menu.Item>
              )}
              <Menu.Item as={Link} to="/emergency-contacts">
                Emergency Contacts
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/contact"
                active={currentPath === "/contact"}
              >
                Contact Us
              </Menu.Item>
            </div>
            <Menu.Menu position="bottom">
              <Menu.Item style={{ paddingBottom: "1.2rem" }}>
                <Button
                  inverted
                  fluid
                  color="red"
                  onClick={() => setModalOpen(true)}
                >
                  Logout
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Sidebar>
          {returnBackButton()}
        </>
      ) : (
        <>
          <Menu
            borderless
            fixed="top"
            fluid
            style={{
              margin: "0",
              height: "80px",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Menu.Item as={Link} to="/" link>
              <Image
                src={logo}
                width="40"
                height="50"
                style={{ marginRight: "1.5em" }}
              />
              <span style={{ fontSize: "1.5rem" }}>Namaste BHU</span>
            </Menu.Item>
            <Menu.Item className="centered-content">
              Welcome 🙏
              <br />
              {username}
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item className="no-print">
                <NavbarSearchComponent />
              </Menu.Item>
              <Menu.Item
                className="no-print"
                as={Link}
                to="/notifications"
                active={currentPath === "/notifications"}
              >
                Notifications
              </Menu.Item>
              {personType !== "STU" && (
                <Menu.Item className="no-print" as={Link} to={uwdUrl}>
                  UWD
                </Menu.Item>
              )}
              <Dropdown className="no-print" text="More" pointing item>
                <Dropdown.Menu>
                  {isUserCSEOPAdmin() && (
                    <Dropdown.Item
                      as={Link}
                      to="/cseop/attendance"
                      active={currentPath === "/cseop/attendance"}
                    >
                      CSEOP Attendance
                    </Dropdown.Item>
                  )}
                  {srkMember && (
                    <Dropdown.Item
                      as={Link}
                      to="/srk"
                      active={currentPath === "/srk"}
                    >
                      SRK
                    </Dropdown.Item>
                  )}
                  {hostelAdminMember && (
                    <Dropdown.Item
                      as={Link}
                      to="/wardens"
                      active={currentPath === "/wardens"}
                    >
                      Wardens
                    </Dropdown.Item>
                  )}
                  {psyCounselingMember && (
                    <Dropdown.Item
                      as={Link}
                      to="/psy/counseling"
                      active={currentPath === "/psy/counseling"}
                    >
                      Psy. Counseling
                    </Dropdown.Item>
                  )}
                  {faculty && (
                    <Dropdown.Item
                      as={Link}
                      to="/mycourses"
                      active={currentPath === "/mycourses"}
                    >
                      My Courses
                    </Dropdown.Item>
                  )}
                  {complaintSalaryMember && (
                    <Dropdown.Item
                      as={Link}
                      to="/issues/salary"
                      active={currentPath === "/issues/salary"}
                    >
                      Salary Issues
                    </Dropdown.Item>
                  )}
                  {/* {personType !== "STU" && (
                    <Menu.Item as={Link} to={uwdUrl}>
                      UWD
                    </Menu.Item>
                  )} */}
                  {teachOrStaff && (
                    <Dropdown.Item
                      as={Link}
                      to="/directory"
                      active={currentPath === "/directory"}
                    >
                      Telephone Directory
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as={Link}
                    to="/faculty/search"
                    active={currentPath === "/faculty/search"}
                  >
                    Faculty Search
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/central-library"
                    active={currentPath === "/central-library"}
                  >
                    Central Library
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/holidays"
                    active={currentPath === "/holidays"}
                  >
                    Holiday Calendar
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/contact"
                    active={currentPath === "/contact"}
                  >
                    Contact Us
                  </Dropdown.Item>
                  {isUserAdmin() && (
                    <Dropdown.Item
                      as={Link}
                      to="/namaste-admin/"
                      active={currentPath === "/namaste-admin"}
                    >
                      Admin
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as={Link}
                    to="/emergency-contacts"
                    active={currentPath === "/emergency-contacts"}
                  >
                    Emergency Contacts
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {userData && (
                <Menu.Item onClick={() => setProfileSidebarVisible(true)}>
                  <Image
                    src={
                      userData.profile_photo
                        ? `https://namaste.bhu.edu.in${userData.profile_photo}`
                        : defaultImage
                    }
                    avatar
                  />
                </Menu.Item>
              )}
            </Menu.Menu>
          </Menu>
          {returnBackButton()}
        </>
      )}
      <Sidebar
        as={Menu}
        direction="right"
        animation="overlay"
        onHide={() => setProfileSidebarVisible(false)}
        vertical
        visible={profileSidebarVisible}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {userData && (
          <div style={{ flex: 1 }}>
            <ProfileCard person_id={userData.id} />
          </div>
        )}
        <Menu.Menu position="bottom">
          <Menu.Item style={{ paddingBottom: "1.2rem" }}>
            <Button
              inverted
              fluid
              color="red"
              onClick={() => setModalOpen(true)}
            >
              Logout
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Sidebar>
      <Modal size="mini" open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader>Logout Confirmation</ModalHeader>
        <ModalContent>
          <p>Are you sure you want to logout?</p>
        </ModalContent>
        <ModalActions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button positive onClick={onLogout}>
            Yes
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default memo(Navbar);
